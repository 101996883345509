$(document).ready(function() {
  $('#login_button').off('click').on('click', function () {
    const userId = $('#userId').val();
    const password = $('#password').val();
 
    const form = new FormData();
    form.set('username', userId);
    form.set('password', password);
 
     // POSTリクエスト
     api.post('/login', form)
       .then(data => console.log(data))
       .catch(error => console.error(error));
  });
});